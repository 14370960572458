<template>
  <div class="wetter">
  <SideBar />
    <div class="container">
    <Search @daily="getDaily" @seven="getSeven" />
    <Cards :daily="dailyData" :seven="sevenData"/> 
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import Cards from "@/components/cards.vue";
import Search from "@/components/search.vue";


export default {
  name: 'Wetter',
  components: { 
    SideBar,
    Cards, 
    Search
    },
  data: () => ({
  sevenData: [],
  dailyData: [],
  darkMode: false,
  showCard: true,

    
  }),
  methods: {
    
    getDaily(data) {
      this.dailyData = data
    },

    getSeven(data) {
      this.sevenData = data
    }

  },

mounted() {

  }
}
</script>


<style>

</style>
 