<template>
  <div class="container">
    <div class="card-1 hidden-md-and-down">
      <h3>aktuelles Wetter</h3>
      <div class="cards">
        <div class="today">
          <h2>{{ daily.name }}</h2>

          <div class="daily">
            <img
              :src="require(`../assets/img/weater_elements/${weathers[daily.weather[0].main]}.svg`)"
              width="220px"
              alt
            /> 
            <p>{{ parseInt(daily.main.temp) }}°</p>
          </div>

          <h2>{{ daily.weather[0].main }}</h2>
        </div> 

        <div class="todayDetails">
          <h3>Gefühlt {{ parseInt(daily.main.feels_like) }}°</h3>
          <h3>
            <img width="64" src="../assets/img/weater_elements/humity.png" alt />
            {{ daily.main.humidity }}%
          </h3>
          <h3>
            <img src="../assets/img/weater_elements/down.png" alt />
            {{ parseInt(daily.main.temp_min) }}°
          </h3>

          <h3>
            <img src="../assets/img/weater_elements/up.png" alt />
            {{ parseInt(daily.main.temp_max) }}°
          </h3>
          <h3>
            <img src="../assets/img/weater_elements/wind.png" alt />
            {{ daily.wind.speed }} km/h
          </h3>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div class="card-1Klein hidden-md-and-up">
      <h4>aktuelles Wetter</h4>
      <div class="cardsKlein">
        <div class="today" cols="12" sm="6">
          <h3>{{ daily.name }}</h3>

          <div class="daysDetail">
            <img
              :src="require(`../assets/img/weater_elements/${weathers[daily.weather[0].main]}.svg`)"
              width="110px"
              alt
            /> 

            <p>{{ parseInt(daily.main.temp) }}°</p>
          </div>

          <h3>{{ daily.weather[0].main }}</h3>
        </div> 

        <div class="side" cols="12" sm="6">
          <h4>Gefühlt {{ parseInt(daily.main.feels_like) }}°</h4>
          <h4>
            <img width="38" src="../assets/img/weater_elements/humity.png" alt />
            {{ daily.main.humidity }}%
          </h4>
          <h4>
            <img width="40" src="../assets/img/weater_elements/down.png" alt />
            {{ parseInt(daily.main.temp_min) }}°
          </h4>
          <h4>
            <img width="40" src="../assets/img/weater_elements/up.png" alt />
            {{ parseInt(daily.main.temp_max) }}°
          </h4>
        </div>
      </div>
    </div>
    <div  class="card-2 hidden-md-and-up">
      <div class="daysDetail">
        <span
          style="display:flex; flex-direction:column; align-items:center; padding-left:10px"
          v-for="data in seven.list"
          :key="data"
        >
        <p>
          {{data.day}}
        </p>
          <img
            :src="require(`../assets/img/weater_elements/${weathers[data.weather[0].main]}.svg`)"
            width="100px"
            alt
          />
          <p>{{ data.weather[0].main }}</p>
          <p>{{ parseInt(data.temp.day) }}° / {{ parseInt(data.temp.night) }}°</p>
        </span>
      </div> 
    </div>

<!-- Desktop -->
    <div  class="card-2 hidden-md-and-down">
      <div class="daysDetail">
        <span
          style="display:flex; flex-direction:column; align-items:center; padding-left:10px"
          v-for="data in seven.list"
          :key="data"
        >
        <p>
          {{data.day}}
        </p>
          <img
            :src="require(`../assets/img/weater_elements/${weathers[data.weather[0].main]}.svg`)"
            width="100px"
            alt
          />
          <p>{{ data.weather[0].main }}</p>
          <p>{{ parseInt(data.temp.day) }}° / {{ parseInt(data.temp.night) }}°</p>
        </span>
      </div> 
    </div>
  </div>
</template>


<script>
export default {
  name: 'Cards',
  props: ['daily', 'seven'],
  data() {
    return {
      
      darkMode: false,
      weathers: {
        Snow: "snowy",
        Clouds: "bewölkt",
        Rain: "rainy",
        Clear: "sunny",
        Thunderstorm: "thunder",
      },
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      daysIndex: [],
    };
  },
  mounted() {
    var currentDate = new Date();
    var nextWeek = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    var days = []
    while (currentDate <= nextWeek) {
      days.push(new Date(currentDate).getDay());
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.daysIndex = days.slice(1);
  },
watch:{
  seven:function(){
     for (let i = 0; i < this.daysIndex.length; i++) {
       this.seven.list[i].day = this.days[this.daysIndex[i]]
    }
  }
}
}

</script>


<style>
.container {
  display: flex;
  flex-direction: column;
  /* width: 50vw !important;  */
}

.cards {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.card-1 {
  width: 100%;
  background-color: #494949;
  border-radius: 10px; 
  margin-top: 10px;
  padding: 10px;
  color: white;
}
.cardsKlein {
  display: flex;
  /* width: 100%; */
  margin-top: 20px;
  /* flex-direction: row; */
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.card-1Klein {
  width: 100%;
  background-color: #494949;
  border-radius: 10px; 
  margin-top: 10px;
  padding: 10px;
  color: white;
}
.side {
  float: right;
  
}

.today {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.todayDetails {
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: flex-start;
}
.daily {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 90px;
}

.card-2 {
  width: 100%;
  height: 300px;
  background-color: #494949;
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow: auto;
  color: white;
}
.days {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 23px;
}
.daysDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 23px;
}

</style>
 