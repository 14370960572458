<template>
  <div class="user-page">
  <SideBar />  
  <div class="container">
  <div class="row">
      <div class="col-lg-7 col-md-5 col-sm-8 mx-auto">
        <v-alert 
          type="success" 
          text
          v-model="showDismissibleAlert">
          Daten wurden gespeichert
        </v-alert>
        <v-alert 
          dismissible
          color="red" 
          text
          v-model="showDismissibleAlert2">
          Username oder Passwort falsch
        </v-alert>
        <v-alert 
          dismissible
          color="red" 
          text
          v-model="showDismissibleAlert3">
          Bitte fülle alle Felder aus
        </v-alert>
        <div class="card">
          <h3>Passwort ändern</h3>
            <v-form v-model="valid" lazy-validation id="myform"> 
            <v-col cols="12" lg="">
                <v-text-field 
                    v-model="form.username" 
                    prepend-icon="mdi-account" 
                    label="username" 
                    name="input-10-1"
                    >
                </v-text-field>
            </v-col>
            <v-col cols="12" lg="">
                <v-text-field 
                    v-model="form.oldPassword"  
                    prepend-icon="mdi-lock" 
                    :append-icon="show1?'eye':'eye-off'" 
                    :type="show1 ? 'text' : 'password'" 
                    name="input-10-1" 
                    label="altes Passwort" 
                    hint="At least 8 characters" 
                    counter 
                    @click:append="show1 = !show1"
                    >
                </v-text-field>
            </v-col>
            <v-col cols="12" lg="">
                <v-text-field 
                    v-model="form.newPassword"  
                    prepend-icon="mdi-lock" 
                    :append-icon="show1?'eye':'eye-off'" 
                    :type="show1 ? 'text' : 'password'" 
                    name="input-10-1" 
                    label="neues Passwort" 
                    hint="At least 8 characters" 
                    counter 
                    @click:append="show1 = !show1"
                    >
                </v-text-field>
            </v-col>
            </v-form>
            <v-card-actions>
                <v-btn color="#7AB879" :loading="loading"  @click.prevent="update"> 
                <!-- @click.native="update" -->
                    <v-icon left dark>check</v-icon>
                    Änderungen speichern
                </v-btn>
            </v-card-actions>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
  export default {
    name: 'User',

    components: {
      SideBar
    },
    data: () => ({
    datenbankName: 'smartgarden',

    loading: false,
    form: {
        username: '',
        oldPassword: '',
        newPassword: '',
    },

    valid: true,
    show1: false,
      
    showDismissibleAlert: false,
    showDismissibleAlert2: false,
    showDismissibleAlert3: false,
    }),
    created() {
    },
    methods: {
      update (){
      var self = this;
      //this.get();
      if(this.form.username != '' && this.form.oldPassword != '' && this.form.newPassword != '' ){
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
          request: 'Zugangsdaten',
            accPalt: this.form.oldPassword,
            accPneu: this.form.newPassword,
            accU: this.form.username,
            dbname: this.datenbankName
        })
        .then(function (erg) {
          console.log(erg);
          
          if(erg.data[0].status==1){
            //alert('gespeichert');
            self.showDismissibleAlert=true
            setTimeout(() => {
            self.showDismissibleAlert=false
         }, 5000);
          }
          else{
            //alert('nein');
            self.showDismissibleAlert2 = true
            setTimeout(() => {
            self.showDismissibleAlert2=false
            }, 5000);
          }
        })
        .catch(function (error) {
            console.log(error);
        }); 
      }
      else {
        //alert('Please enter username & password');
        this.showDismissibleAlert3=true
          setTimeout(() => {
           this.showDismissibleAlert3=false
         }, 5000);
      }
    },
  },
  mounted() {

  },
    
};
</script>
<style scoped>
.user-page {
  align-items: center;
  display: flex;
  height: 85vh; 
}
h3 {
  color: #7AB879;
}
.bgg{
  background: #8FBC8F;
}
.card {
   padding: 45px;
   background-color: #494949;
   opacity: 1;
}

</style>