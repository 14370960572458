<template>
  <div class="wasserstand">
  <SideBar />  
   <div class="login-page">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-4 col-sm-8 mx-auto ">
        <v-simple-table
          fixed-header
          >
          <template v-slot:default >
          <thead >
            <tr>
              <th class="text-center text-subtitle-1">
                Wasserstandswert
              </th>
              <th class="text-center text-subtitle-1">
                Datum 
              </th>
            </tr>
          </thead>
          <tbody class="bgg text-center">
            <tr
              v-for="item in wasserstandsdaten"
              :key="item.wert"
            >
              <td>{{ item.Wasserstandswert }}</td>
              <td>{{ item.Zeitstempel }}</td>
            </tr>
          </tbody>
          </template>
          </v-simple-table>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import EventBus from '../EventBus';

  export default {
    name: 'Wasserstand',
     props: ['datenbank'],

    components: {
      SideBar
    },
    data: () => ({
      wasserstandsdaten: [
        {

        },
      ],
      datenbankName: 'smartgarden',
      standort: 'Standort 1',
    }),
    created() {
    const getStandort = function(ort) {
      this.standort = ort;
      this.$cookies.set('ort', this.standort);
      if(this.standort == 'Standort 2')
      {
        this.datenbankName = 'smartgarden-2'
      }
      else
      {
        this.datenbankName = 'smartgarden'
      }
    }
    EventBus.$on('select', getStandort);
    },
    methods: {
      table() {
      var self = this;
      this.get();
        this.axios.post('https://smartgarden.htl.jetzt/Diplomarbeit/db.php', {
          request: 'WasserstandsWerteTabelle',
          dbname: this.datenbankName
        })
        .then(function (daten) {
            const  ka = JSON.parse((JSON.stringify(daten.data)));
            console.log(ka);
            self.wasserstandsdaten = ka;
        })
        .catch(function (error) {
            console.log(error);
        }); 
    },
    get() {
      if (this.$cookies.isKey('ort')) {
        this.standort= this.$cookies.get('ort');
        if (this.$cookies.get('ort') == "Standort 1") {
            this.datenbankName = 'smartgarden';
        }
        else {
            this.datenbankName = 'smartgarden-2';
          }
        }
    }
  },
  mounted() {
    this.table();
    this.setIntervalId = setInterval(this.table, 60000/10)
    this.get();
  },
    
};
</script>
<style scoped>
.login-page {
   align-items: center;
   display: flex;
   height: 85vh;
}
.bgg{
  background: #8FBC8F;
}
</style>